import React, { Fragment } from "react";
import { Box, Flex, Text } from "rebass";
import Card from "./Card";

//Assets
//import img1 from "/static/models/textures/oberon-cover-travelbase.jpg"; 

function OberonProjectCards() {
  return (
    <Flex
      flexWrap={["wrap", "wrap", "wrap","nowrap"]}
      variant="container"
      pt={[3, 4]}
      mb={[4, 5]}
      sx={{ gap: [2, 3] }}
      ml={["0", "0", "0", "0", "25ch"]}
    >
      <Box width={[1 / 1, 1 / 1, 1 / 1, 1 / 2, 1 / 3]} mb={[3]}>
        <Card
          sx={{
            backgroundColor: "oberonProjectBgTravelbase",
            color: "oberonProjectTextTravelbase",
            "& .label": {
            backgroundColor: "oberonProjectTextTravelbase",
            color:"oberonProjectBgTravelbase"
            },
            "& .label::after": {
            borderLeftColor:"oberonProjectTextTravelbase",
            }
          }}
          eyebrow={"Showcase"}
          year={"2021"}
          title={"Travelbase platform"}
          page="/work/oberon/travelbase"
          info={
            <Fragment>
              <Text as="span">
                <strong>Booking System, SaaS Website, Design System</strong>
              </Text>
            </Fragment>
          }
          //img={img1}
        />
      </Box>
      <Box width={[1 / 1, 1 / 1, 1 / 1, 1 / 2, 1 / 3]} mb={[3]}>
        <Card
          sx={{
            backgroundColor: "oberonProjectBgEasybroker",
            color: "oberonProjectTextEasybroker",
            "& .label": {
            backgroundColor: "oberonProjectTextEasybroker",
            color:"oberonProjectBgEasybroker"
            },
            "& .label::after": {
            borderLeftColor:"oberonProjectTextEasybroker",
            }
          }}
          eyebrow={"Showcase"}
          year={"2022 (4 months)"}
          title={"Easybroker App"}
          page="/work/oberon/easybroker-app"
          info={
            <Fragment>
              <Text as="span">
                <strong>Investment App, React Native, Product Design</strong>
              </Text>
            </Fragment>
          }
          //img={img1}
        />
      </Box>
    </Flex>
  );
}

export default OberonProjectCards;

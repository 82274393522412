import React, { Fragment } from "react";
import { Box, Flex, Text } from "rebass";
import Card from "./Card";

//Assets
//import img1 from "../img/card-image-test.png";

function AccellProjectCards() {
  return (
    <Flex
      flexWrap={["wrap", "wrap", "wrap","nowrap"]}
      variant="container"
      pt={[3, 4]}
      mb={[4, 5]}
      sx={{ gap: [2, 3] }}
      ml={["0", "0", "0", "0", "25ch"]}
    >
      <Box width={[1 / 1, 1 / 1, 1 / 1, 1 / 2, 1 / 3]} mb={[3]}>
        <Card
          sx={{
            backgroundColor: "accellProjectBgDs",
            color: "accellProjectTextDs",
            "& .label": {
            backgroundColor: "accellProjectTextDs",
            color:"accellProjectBgDs"
            },
            "& .label::after": {
            borderLeftColor:"accellProjectTextDs",
            }
          }}
          eyebrow={"Case study"}
          year={"2020 - 2021"}
          title={"Design System(s)"}
          page="/work/accell-group/design-system/"
          info={
            <Fragment>
              <Text as="span">
                <strong>eCommerce, Multi-brand, Design System</strong>
              </Text>
            </Fragment>
          }
        />
      </Box>
      <Box width={[1 / 1, 1 / 1, 1 / 1, 1 / 2, 1 / 3]} mb={[3]}>
        <Card
          sx={{
            backgroundColor: "accellProjectBgECom",
            color: "accellProjectTextECom",
            "& .label": {
            backgroundColor: "accellProjectTextECom",
            color:"accellProjectBgECom"
            },
            "& .label::after": {
            borderLeftColor:"accellProjectTextECom",
            }
          }}
          eyebrow={"Case study"}
          year={"2020 - 2021"}
          title={"eCommerce Platform"}
          page="/work/accell-group/ecom-platform/"
          info={
            <Fragment>
              <Text as="span">
                <strong>Headless, Multi-brand, Design System</strong>
              </Text>
            </Fragment>
          }
        />
      </Box>
      <Box width={[1 / 1, 1 / 1, 1 / 1, 1 / 2, 1 / 3]} mb={[3]}>
        <Card
          sx={{
            backgroundColor: "accellProjectBgLP",
            color: "accellProjectTextLP",
            "& .label": {
            backgroundColor: "accellProjectTextLP",
            color:"accellProjectBgLP"
            },
            "& .label::after": {
            borderLeftColor:"accellProjectTextLP",
            }
          }}
          eyebrow={"Case study"}
          year={"2021"}
          title={"L'Équipe LAPIERRE"}
          page="/work/accell-group/lapierre/"
          info={
            <Fragment>
              <Text as="span">
                <strong>eCommerce, Website, Visual Design</strong>
              </Text>
            </Fragment>
          }
        />
      </Box>
    </Flex>
  );
}

export default AccellProjectCards;
